@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-50 text-gray-900;
  font-family: 'Inter', sans-serif;
}

.custom-transition {
  @apply transition-all duration-300 ease-in-out;
}

.hover-lift {
  @apply transition-all hover:-translate-y-1 hover:shadow-lg;
}

.button {
  @apply px-4 py-2 rounded-full font-medium transition-all;
}

.button-primary {
  @apply bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.button-secondary {
  @apply bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400;
}

.input {
  @apply w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500;
}

.range-thumb-hidden::-webkit-slider-thumb {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.range-thumb-hidden::-moz-range-thumb {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.range-thumb-visible::-webkit-slider-thumb {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.range-thumb-visible::-moz-range-thumb {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* Add these new styles */
.range-thumb-hidden:hover::-webkit-slider-thumb,
.range-thumb-hidden:active::-webkit-slider-thumb,
.range-thumb-hidden:focus::-webkit-slider-thumb {
  opacity: 1;
}

.range-thumb-hidden:hover::-moz-range-thumb,
.range-thumb-hidden:active::-moz-range-thumb,
.range-thumb-hidden:focus::-moz-range-thumb {
  opacity: 1;
}

.waveform-box {
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05); /* Light gray background */
  border-radius: 4px;
  overflow: hidden;
}

.waveform {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 80px; /* This should match the height in the WaveSurfer options */
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.animate-scroll {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation: scroll 15s linear infinite;
}

.title-container {
  width: 100%;
  overflow: hidden;
}

